// dP     dP                   oo          dP       dP
// 88     88                               88       88
// 88    .8P .d8888b. 88d888b. dP .d8888b. 88d888b. 88 .d8888b. .d8888b.
// 88    d8' 88'  `88 88'  `88 88 88'  `88 88'  `88 88 88ooood8 Y8ooooo.
// 88  .d8P  88.  .88 88       88 88.  .88 88.  .88 88 88.  ...       88
// 888888'   `88888P8 dP       dP `88888P8 88Y8888' dP `88888P' `88888P'
$gap: 3rem

// d888888P oo   dP                     oo dP dP
//    88         88                        88 88
//    88    dP d8888P 88d888b. .d8888b. dP 88 88 .d8888b.
//    88    88   88   88'  `88 88'  `88 88 88 88 88ooood8
//    88    88   88   88       88.  .88 88 88 88 88.  ...
//    dP    dP   dP   dP       `88888P8 dP dP dP `88888P'

body
  font-family: Raleway

h4
  color: var(--color-primary)
  font-weight: normal

  // font-size: 16px

// dP     dP   dP   oo dP
// 88     88   88      88
// 88     88 d8888P dP 88 .d8888b.
// 88     88   88   88 88 Y8ooooo.
// Y8.   .8P   88   88 88       88
// `Y88888P'   dP   dP dP `88888P'

.grid2cols
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: $gap

  @media screen and (min-width: 0) and (max-width: 600px)
    grid-template-columns: 1fr

.grid3cols
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: $gap

  @media screen and (min-width: 0) and (max-width: 600px)
    grid-template-columns: 1fr

.grid4cols
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-gap: $gap

  @media screen and (min-width: 401px) and (max-width: 900px)
    grid-template-columns: 1fr 1fr

  @media screen and (min-width: 0) and (max-width: 400px)
    grid-template-columns: 1fr

.error
  color: red

.success
  color: green

// dP
// 88
// 88 88d8b.d8b. .d8888b. .d8888b. .d8888b. .d8888b.
// 88 88'`88'`88 88'  `88 88'  `88 88ooood8 Y8ooooo.
// 88 88  88  88 88.  .88 88.  .88 88.  ...       88
// dP dP  dP  dP `88888P8 `8888P88 `88888P' `88888P'
//                             .88
//                         d8888P

.image
  &.fit
    width: 100%

  &.right
    width: 30%

  img
    width: 100%

// #header
//   &.alt
//     h1 strong
//       display: none

//   &:not(.alt)
//     .logo img
//       display: none

// 8888ba.88ba
// 88  `8b  `8b
// 88   88   88 .d8888b. 88d888b. dP    dP
// 88   88   88 88ooood8 88'  `88 88    88
// 88   88   88 88.  ... 88    88 88.  .88
// dP   dP   dP `88888P' dP    dP `88888P'
//
#header
  nav
    top: 1rem

    li
      border-left: 0

    .id5 ul
      display: none !important

    .active a
      color: var(--color-primary--light)

  &.alt
    .active a
      color: white

.p11 .menu a:hover
  color: var(--color-primary--verylighter) !important

//  888888ba
//  88    `8b
// a88aaaa8P' .d8888b. 88d888b. 88d888b. .d8888b. 88d888b.
//  88   `8b. 88'  `88 88'  `88 88'  `88 88ooood8 88'  `88
//  88    .88 88.  .88 88    88 88    88 88.  ... 88
//  88888888P `88888P8 dP    dP dP    dP `88888P' dP

#banner
  background-image: url("../images/accueil/banner.jpg")
  background-size: cover

  &::after
    -moz-transition: opacity 2s ease-in-out
    -webkit-transition: opacity 2s ease-in-out
    -ms-transition: opacity 2s ease-in-out
    transition: opacity 2s ease-in-out
    background: #2a313b
    content: ""
    height: 100%
    left: 0
    opacity: 0.6
    position: absolute
    top: 0
    width: 100%
    z-index: 1

  h2
    text-transform: uppercase

  p
    color: white

//  .d888888                                      oo            dP   oo
// d8'    88                                                    88
// 88aaaaa88a .d8888b. .d8888b. .d8888b. .d8888b. dP .d8888b. d8888P dP .d8888b. 88d888b.
// 88     88  Y8ooooo. Y8ooooo. 88'  `88 88'  `"" 88 88'  `88   88   88 88'  `88 88'  `88
// 88     88        88       88 88.  .88 88.  ... 88 88.  .88   88   88 88.  .88 88    88
// 88     88  `88888P' `88888P' `88888P' `88888P' dP `88888P8   dP   dP `88888P' dP    dP

.association
  &__whoswho
    figcaption
      font-size: 0.8em

    img
      height: 250px
      object-fit: cover

      @media screen and (min-width: 0) and (max-width: 600px)
        height: 200px

    margin: $gap auto

//  888888ba                      dP                              oo
//  88    `8b                     88
// a88aaaa8P' .d8888b. 88d888b. d8888P .d8888b. 88d888b. .d8888b. dP 88d888b. .d8888b. .d8888b.
//  88        88'  `88 88'  `88   88   88ooood8 88'  `88 88'  `88 88 88'  `88 88ooood8 Y8ooooo.
//  88        88.  .88 88         88   88.  ... 88    88 88.  .88 88 88       88.  ...       88
//  dP        `88888P8 dP         dP   `88888P' dP    dP `88888P8 dP dP       `88888P' `88888P'

.partenaires
  display: grid
  grid-template-columns: repeat(5, 1fr)
  grid-gap: $gap
  align-items: center

  @media screen and (min-width: 500px) and (max-width: 800px)
    grid-template-columns: repeat(3, 1fr)

  @media screen and (min-width: 0px) and (max-width: 499px)
    grid-template-columns: repeat(2, 1fr)

  img
    width: 100%

  figcaption
    display: none

//  a88888b.                     dP                       dP
// d8'   `88                     88                       88
// 88        .d8888b. 88d888b. d8888P .d8888b. .d8888b. d8888P
// 88        88'  `88 88'  `88   88   88'  `88 88'  `""   88
// Y8.   .88 88.  .88 88    88   88   88.  .88 88.  ...   88
//  Y88888P' `88888P' dP    dP   dP   `88888P8 `88888P'   dP
.p6
  iframe
    margin-bottom: $gap

//  88888888b                     dP
//  88                            88
// a88aaaa    .d8888b. .d8888b. d8888P .d8888b. 88d888b.
//  88        88'  `88 88'  `88   88   88ooood8 88'  `88
//  88        88.  .88 88.  .88   88   88.  ... 88
//  dP        `88888P' `88888P'   dP   `88888P' dP

#footer
  max-width: 90%
  margin: 0 auto
  display: grid
  grid-template-columns: 150px 1fr
  align-items: center
  padding: 0

  .logo
    width: 150px
    border: 0

    img
      width: 100%

.copyright
  text-align: right
